import React from 'react'
import styles from './index.module.css'
import Button from '../../Button'
// import ReactMardown from 'react-markdown'

import clients from './clientsLogo'
import partners from './partnersLogo'

const Partners = ({ data }) => {
  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <h2 className={styles.title}>partners</h2>

        <div className={styles.partnersContainer}>
          <div className={styles.partnersLeft}>
            <h3 className={styles.partnersTitle}>{data.partners_title_1}</h3>
            <div className={styles.listItems}>
              {clients}
              {/* <ReactMardown
                escapeHtml={false}
                source={data.partners_images_1}
              /> */}
            </div>
          </div>

          <div className={styles.partnersRight}>
            <h3 className={styles.partnersTitle}>{data.partners_title_2}</h3>
            <div className={styles.hardware}>
              {partners}
              {/* <ReactMardown
                escapeHtml={false}
                source={data.partners_images_2}
              /> */}
            </div>
            <div className={styles.btnWrapper}>
              <Button to="/references/" rootStyle={styles.btn}>
                View all partners
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partners
