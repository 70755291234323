import React from 'react'
import styles from './index.module.css'
// import YouTube from 'react-youtube'
// import video from './iFeelSmart_AndroidTV_OperatorTier_IBC2018.mp4'
// import video from './VIdeo Google Centaurus_10_1.mp4'
import video from './assets/videoMayaCentaurus.mp4'
import poster from './assets/HomeCoachUpdate.jpg'
import Cover from 'react-video-cover'
import { isIOS } from 'react-device-detect'

const evts = [
  'mozfullscreenchange',
  'webkitfullscreenchange',
  'MSFullscreenChange',
  'msfullscreenchange',
]

class Banner extends React.Component {
  // TODO improve transition between in/out fullscreen

  state = {
    resizeNotifier: () => {},
  }

  constructor(props) {
    super(props)
    this.onBtnClick = this.onBtnClick.bind(this)
    this.onFullscreenChange = this.onFullscreenChange.bind(this)
  }

  updateObjectFit(value) {
    this.videoRef.style.objectFit = value
  }

  isFullscreen() {
    const element =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    return element && element.nodeName === 'VIDEO'
  }

  onFullscreenChange() {
    this.updateObjectFit(this.isFullscreen() ? 'contain' : 'cover')
  }

  componentDidMount() {
    evts.forEach(evtName => {
      document.addEventListener(evtName, this.onFullscreenChange)
    })
  }

  componentWillUnmount() {
    evts.forEach(evtName => {
      document.removeEventListener(evtName, this.onFullscreenChange)
    })
  }

  onBtnClick() {
    const cancelFullScreen =
      document.cancelFullScreen ||
      document.mozCancelFullScreen ||
      document.webkitCancelFullScreen ||
      document.webkitExitFullscreen
    if (!this.isFullscreen()) {
      if (document.documentElement.requestFullScreen) {
        this.videoRef.requestFullScreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        this.videoRef.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullScreen) {
        this.videoRef.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
      }
    } else {
      if (cancelFullScreen) {
        cancelFullScreen()
      }
    }
  }

  render() {
    const data = this.props.data
    const videoOptions = {
      src: video,
      autoPlay: true,
      loop: true,
      muted: true,
      poster: poster,
      ref: videoRef => {
        this.videoRef = videoRef
      },
      onClick: () => {
        if (
          (document.fullScreenElement && document.fullScreenElement !== null) ||
          (!document.mozFullScreen && !document.webkitIsFullScreen)
        ) {
          if (document.documentElement.requestFullScreen) {
            this.videoRef.requestFullScreen()
            this.videoRef.style.objectFit = 'unset'
          } else if (document.documentElement.mozRequestFullScreen) {
            this.videoRef.mozRequestFullScreen()
            this.videoRef.style.objectFit = 'unset'
          } else if (document.documentElement.webkitRequestFullScreen) {
            this.videoRef.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
            this.videoRef.style.objectFit = 'unset'
          }
        } else {
          if (document.cancelFullScreen) {
            document.cancelFullScreen()
            this.videoRef.style.objectFit = 'cover'
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
            this.videoRef.style.objectFit = 'cover'
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen()
            this.videoRef.style.objectFit = 'cover'
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen()
            this.videoRef.style.objectFit = 'cover'
          }
        }
      },
    }

    return (
      <div className={styles.banner}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>{data.banner_title}</h1>
          {!isIOS && (
            <button onClick={this.onBtnClick} className={styles.fullscreenBtn}>
              Play Fullscreen
            </button>
          )}
        </div>
        {!isIOS && (
          <div className={styles.backgroundVideo}>
            <div className={styles.bg} />
            <Cover videoOptions={videoOptions} remeasureOnWindowResize />
          </div>
        )}
      </div>
    )
  }
}

export default Banner
