import React from 'react'
import classnames from 'classnames'
import './assets/BackgroundAbout.jpg'
import styles from './index.module.css'
import Button from '../../Button'
import InfoBanner from '../../InfoBanner'
import Markdown from 'markdown-to-jsx'

// TODO: improve the logic of the '1st'

const About = ({ data }) => {
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.flexRow}>
          <div className={styles.left}>
            <div className={styles.titleContainer}>
              <h2 className={styles.title}>{data.about_title}</h2>
              <Markdown>{data.about_text}</Markdown>
            </div>
          </div>

          <div className={styles.right}>
            <div className={styles.aboutList}>
              {/* <Markdown>{data.about_list}</Markdown> */}
              <p>
                <span className={classnames(styles.bigWord, styles.first)}>
                  1
                </span>{' '}
                company to have launched Android TV CAS secured IPTV in January
                2015
              </p>
              <p>
                <span className={styles.bigWord}>3</span> millions of managed
                Android TV STB deployed in France
              </p>
              <p>
                <span className={styles.bigWord}>6</span> years of experience on
                GoogleTV / AndroidTV
              </p>
              <p>
                <span className={styles.bigWord}>13</span> Multiscreen devices
                supported (iOS / Android / AppleTV / FireTV / Chrome...)
              </p>
            </div>
          </div>
        </div>

        <div className={styles.down}>
          <Button to="/about/">View more</Button>
        </div>
      </div>

      <InfoBanner>
        <p className={styles.infoContent}>{data.about_infobanner}</p>
      </InfoBanner>
    </div>
  )
}

export default About
