import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'

import Banner from '../components/HomePage/Banner'
import About from '../components/HomePage/About'
import Aggregator from '../components/SolutionsPage/Aggregator'
import Products from '../components/HomePage/Products'
import Partners from '../components/HomePage/Partners'
import Careers from '../components/HomePage/Careers'
// import News from '../components/HomePage/News'

export default ({ data }) => (
  <Layout>
    <Banner data={data.allStrapiHomepage.edges[0].node} />
    <About data={data.allStrapiHomepage.edges[0].node} />
    <Aggregator homePage />
    <Products data={data.allStrapiHomepage.edges[0].node} />
    <Partners data={data.allStrapiHomepage.edges[0].node} />
    <Careers />
    {/* <News data={data.allStrapiNews.edges} /> */}
  </Layout>
)

export const pageQuery = graphql`
  query IndexQuery {
    allStrapiHomepage {
      edges {
        node {
          id
          banner_title
          about_title
          about_text
          about_list
          about_infobanner
          products_title
          products_subtitle
          products_text
          products_infobanner
          partners_title_1
          partners_title_2
          partners_images_1
          partners_images_2
        }
      }
    }
    allStrapiNews {
      edges {
        node {
          id
          createdAt
          title
          mailto
          content
          cover {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
