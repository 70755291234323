import React from 'react'
import uuid from 'uuid/v4'

import technicolor from './assets/hardware/color/20-Technicolor.png'
import sagemcom from './assets/hardware/color/21-Sagemcom.png'
import broadcom from './assets/hardware/color/12-Broadcom.png'
import kaltura from './assets/hardware/color/33-Kaltura.png'
import synaptics from './assets/hardware/color/11-Synpatics.png'
import comcast from './assets/hardware/color/35-Comcast.png'
import verimatrix from './assets/hardware/color/verimatrix.png'
import irdeto from './assets/hardware/color/irdeto.png'
import thinkAnalytics from './assets/hardware/color/42-ThinkAnalytics.png'
import viaccessOrca from './assets/hardware/color/viaccessOrca-color.png'
import amdocs from './assets/hardware/color/amdocs-black.png'
import seirobotics from './assets/hardware/color/logo_sei_robotics.png'

const partnersArray = [
  {
    logo: technicolor,
    alt: 'Technicolor',
  },
  {
    logo: sagemcom,
    alt: 'Sagemcom',
  },
  {
    logo: broadcom,
    alt: 'Broadcom',
  },
  {
    logo: kaltura,
    alt: 'kaltura',
  },
  {
    logo: synaptics,
    alt: 'synaptics',
  },
  {
    logo: comcast,
    alt: 'comcast',
  },
  {
    logo: verimatrix,
    alt: 'verimatrix',
  },
  {
    logo: irdeto,
    alt: 'irdeto',
  },
  {
    logo: thinkAnalytics,
    alt: 'thinkAnalytics',
  },
  {
    logo: viaccessOrca,
    alt: 'viaccessOrca',
  },
  {
    logo: amdocs,
    alt: 'amdocs',
  },
  {
    logo: seirobotics,
    alt: 'seirobotics',
  },
]

const partnersLogo = partnersArray.map(partner => {
  return (
    <figure key={uuid()}>
      <img src={partner.logo} alt={partner.alt} />
    </figure>
  )
})

export default partnersLogo
