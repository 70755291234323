import React from 'react'
import classnames from 'classnames'
import styles from './index.module.css'
import Button from '../../Button'
import emi from './assets/emiWhite.svg'
import Markdown from 'markdown-to-jsx'

import InfoBanner from '../../InfoBanner'

const Products = ({ data }) => {
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.right}>
          <h2 className={styles.title}>Products</h2>
        </div>

        <div className={styles.left}>
          <div className={styles.content}>
            <h3>{data.products_subtitle}</h3>
            <Markdown options={{ forceBlock: true }}>
              {data.products_text}
            </Markdown>
          </div>
          <div className={styles.devices}>
            <div
              className={classnames(
                styles.active,
                styles.screentv,
                styles.device
              )}
            ></div>
            <div className={classnames(styles.tablet, styles.device)}></div>
            <div className={classnames(styles.mobile, styles.device)}></div>
            <div className={classnames(styles.laptop, styles.device)}></div>
          </div>
          <div>
            <Button to="/products/">View&nbsp;all&nbsp;products</Button>
          </div>
        </div>
      </div>

      <InfoBanner rootStyle={styles.infoBanner}>
        <img src={emi} alt="emi logo" />
        <Markdown options={{ forceBlock: true }}>
          {data.products_infobanner}
        </Markdown>
      </InfoBanner>
    </div>
  )
}

export default Products
