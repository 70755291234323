import React from 'react'
import './assets/BackgroundCareers.jpg'
import paris from './assets/eiffel-tower.svg'
import usa from './assets/statue-of-liberty.svg'
import singapore from './assets/Singapore.png'
import styles from './index.module.css'
// import Button from '../../Button'
// import Hr from '../../Hr'

const Careers = () => {
  return (
    <div className={styles.section}>
      <div className={styles.container}>
        <div className={styles.jobs}>
          <div className={styles.job}>
            <div className={styles.jobTitle}>
              <h4>We’re always looking for new talent</h4>
              {/* <Button to="/" rootStyle={styles.jobBtn} >
                View job
              </Button> */}
              <a
                href="https://www.welcometothejungle.co/companies/ifeelsmart/jobs"
                target="blank"
                className={styles.jobBtn}
              >
                View&nbsp;job
              </a>
            </div>
            <p>
              You are a passionate, dedicated developper, willing to work in an
              international environment&nbsp;? Let's see if we're a good fit.
            </p>
          </div>
          {/* <Hr /> */}
          {/* <div className={styles.job}>
            <div className={styles.jobTitle}>
              <h4>Front end/Creative developper</h4>
              <Button to="/" rootStyle={styles.jobBtn}>
                View job
              </Button>
            </div>
            <p>We’re looking for a mid level front end/Creative Developper to join our digital team</p>
          </div> */}
          {/* <Hr /> */}
          {/* <div className={styles.job}>
            <div className={styles.jobTitle}>
              <h4>Front end/Creative developper</h4>
              <Button to="/" rootStyle={styles.jobBtn}>
                View job
              </Button>
            </div>
            <p>We’re looking for a mid level front end/Creative Developper to join our digital team</p>
          </div> */}
        </div>

        <div className={styles.siteContainer}>
          <h2 className={styles.title}>careers</h2>

          <div className={styles.site}>
            <div className={styles.siteTitle}>
              <img src={paris} alt="Paris" />
              <h3>Paris</h3>
            </div>
            <p>12 rue de Turgot 75009 Paris, France</p>
          </div>
          <div className={styles.site}>
            <div className={styles.siteTitle}>
              <img src={usa} alt="USA" />
              <h3>New-York</h3>
            </div>
            <p>31W 34th Street New York 10001, USA</p>
          </div>
          <div className={styles.site}>
            <div className={styles.siteTitle}>
              <img
                style={{ height: '50px', marginRight: '10px' }}
                src={singapore}
                alt="USA"
              />
              <h3>Singapore</h3>
            </div>
            <p>18 Robinson Road, Level 02-03, 048547 Singapore</p>
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        {/* <Button to="/careers" rootStyle={styles.btn}>
          View all
        </Button> */}
      </div>
    </div>
  )
}

export default Careers
