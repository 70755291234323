import React from 'react'
import uuid from 'uuid/v4'

import bouygues from './assets/clients/bouyguesTelecom.svg'
import partner from './assets/clients/partner.svg'
import ctl from './assets/clients/centurylink-logo-black-text.png'
import wow from './assets/clients/WOW_Logo_only.png'

const clientsArray = [
  {
    logo: bouygues,
    alt: 'Bouygues Telecom',
    region: '(France)',
  },
  {
    logo: partner,
    alt: 'Partner TV',
    region: '(Israel)',
  },
  {
    logo: ctl,
    alt: 'Century Link',
    region: '(USA)',
  },
  {
    logo: wow,
    alt: 'WOW',
    region: '(USA)',
  },
]

const clients = clientsArray.map(client => {
  return (
    <figure key={uuid()}>
      <img src={client.logo} alt={client.alt} />
      <figcaption>{client.region}</figcaption>
    </figure>
  )
})

export default clients
